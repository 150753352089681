/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"

interface LayoutProps {
  children: any;
  activeGroup?: "convert" | "format" | "crypto" | null;
  secondHeaderText?: string | null;
}

const Layout = ({ children, activeGroup, secondHeaderText }: LayoutProps) => {
  return (
    <>
      <Header activeGroup={activeGroup} />
      {secondHeaderText && (
        <header className="bg-white shadow">
          <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h1 className="text-xl font-bold text-gray-900">
              {secondHeaderText}
            </h1>
          </div>
        </header>
      )}
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="px-4 py-6 sm:px-0">
            <div className="rounded-lg h-96">{children}</div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Layout
